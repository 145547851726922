'use client'
import { type FC } from 'react'
import styled from 'styled-components'
import { designGrid, font20_24, font30_42, mq, vw } from '@/styles'
import { Button } from './Button'
import { useTranslator } from '@/hooks'
import { useParams } from 'next/navigation'

export const MainHome:FC = () => {
  const { t } = useTranslator()
  const { lang } = useParams()

  return (
    <Main>
      <section>
        <div className='left'>
          <figure>
            <img src='/images/home-bottle-1.svg' alt='image v-enology' />
          </figure>
          <figure>
            <img src='/images/home-bottle-2.svg' alt='image v-enology' />
          </figure>
          <figure>
            <img src='/images/home-bottle-3.svg' alt='image v-enology' />
          </figure>
        </div>
        <div className='right'>
          <figure>
            <img src='/images/home-bottle-4.svg' alt='image v-enology' />
          </figure>
          <figure>
            <img src='/images/home-bottle-5.svg' alt='image v-enology' />
          </figure>
          <figure>
            <img src='/images/home-bottle-6.svg' alt='image v-enology' />
          </figure>
        </div>
        <div className='mobile'>
          <figure>
            <img src='/images/home-bottle-3.svg' alt='image v-enology' />
          </figure>
          <figure>
            <img src='/images/home-bottle-1.svg' alt='image v-enology' />
          </figure>
          <figure>
            <img src='/images/home-bottle-2.svg' alt='image v-enology' />
          </figure>
          <figure>
            <img src='/images/home-bottle-4.svg' alt='image v-enology' />
          </figure>
          <figure>
            <img src='/images/home-bottle-5.svg' alt='image v-enology' />
          </figure>
          <figure>
            <img src='/images/home-bottle-6.svg' alt='image v-enology' />
          </figure>
        </div>
        {lang !== 'pt' && lang !== 'de' &&
          <div className='logo-cta'>
            <figure>
              <img src='/images/svg/logo-hellomamma.svg' alt='image logo' />
            </figure>
              <h2>{t('main_one')}</h2>
              <Button bgType='red'><a href={t('main_link') as string}>{t('main_two')}</a></Button>
          </div>
        }
      </section>
    </Main>
  )
}

const Main = styled.main`
  section {
    ${designGrid({})}
    position: relative;
    overflow: hidden;

    .mobile {
      position: absolute;
      bottom: ${vw(30, 'mobile')};
      width: ${vw(643, 'mobile')};
      display: flex;
      align-items: flex-end;
      column-gap: ${vw(25, 'mobile')};
      transform: translateX(-${vw(184, 'mobile')});

      ${mq.greaterThan('tablet')} {
        display: none;
      }
    }

    .left,
    .right {
      display: none;

      ${mq.greaterThan('tablet')} {
        display: flex;
        align-items: flex-end;
        column-gap: ${vw(40, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        column-gap: 40px;
      }
    }

    .left {
      ${mq.greaterThan('tablet')} {
        grid-area: 1 / 1 / span 1 / span 5;
        padding: 0 ${vw(20, 'desktop')} ${vw(46, 'desktop')} ${vw(41, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 0 20px 46px 41px;
      }
    }

    .right {
      ${mq.greaterThan('tablet')} {
        grid-area: 1 / 9 / span 1 / span 4;
        padding: 0 ${vw(36, 'desktop')} ${vw(46, 'desktop')} ${vw(15, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 0 36px 46px 15px;
      }
    }

    .left figure,
    .right figure,
    .mobile figure {
      flex-grow: 1;
    }

    .logo-cta {
      grid-area: 1 / 1 / span 1 / span 6;
      margin-top: ${vw(100, 'mobile')};
      margin-bottom: ${vw(360, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        margin-top: ${vw(100, 'tablet')};
        margin-bottom: ${vw(360, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        grid-area: 1 / 5 / span 1 / span 4;
        margin-bottom: ${vw(260, 'desktop')};
        margin-top: ${vw(160, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        margin-bottom: 260px;
        margin-top: 160px;
      }

      figure {
        margin: 0 ${vw(100, 'mobile')} ${vw(40, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          margin: 0 ${vw(100, 'tablet')} ${vw(40, 'tablet')};
        }

        ${mq.greaterThan('tablet')} {
          margin: 0 ${vw(65, 'desktop')} ${vw(80, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin: 0 65px 80px;
        }
      }

      h2 {
        ${font20_24(true)}
        margin-bottom: ${vw(40, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          margin-bottom: ${vw(40, 'tablet')};
        }

        ${mq.greaterThan('tablet')} {
          ${font30_42(false)}
          margin-bottom: ${vw(40, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          margin-bottom: 40px;
        }
      }
    }
  }
`
