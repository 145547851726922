import { type FC, type ReactNode, memo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, font15_20, font18_22 } from '@/styles'

const handleBgType = (bgType) => {
  switch (bgType) {
    case 'red':
      return '#76273C'
    default:
      return '#EFDCC7'
  }
}

const handleBgTypeHover = (bgType) => {
  switch (bgType) {
    case 'white':
      return '#76273C'
    case 'red':
      return '#EFDCC7'
    default:
      return '#76273C'
  }
}

const handleBgTypeHover2 = (bgType) => {
  switch (bgType) {
    case 'red':
      return '#76273C'
    default:
      return '#EFDCC7'
  }
}

interface IButton {
  bgType?: 'background' | 'white' | 'orange' | 'red',
  children: ReactNode,
  circle?: boolean,
  className?: string,
  disabled?: boolean,
  id?: any,
  name?: string
  onClick?: any,
  outline?: boolean,
  type?: 'button' | 'submit',
  uppercase?: boolean,
  noHoverEffect?: boolean,
  initial?: any,
  variants?: any,
  exit?: any,
  animate?: any
}

export const Button:FC<IButton> = memo(({ bgType = 'default', children, circle = false, disabled = false, className = '', id, name = 'Button', onClick = null, outline = false, type = 'button', uppercase = false, noHoverEffect = false, initial, variants, exit, animate }) => {
  return (
    <ButtonStyled {...(id && { id })} className={className} onClick={onClick} type={type} $bgType={bgType} $circle={circle} disabled={disabled} $uppercase={uppercase} name={name} $outline={outline} $noHoverEffect={noHoverEffect} {...(initial && { initial })} {...(variants && { variants })} {...(exit && { exit })}  {...(animate && { animate })} >
      <div className='button__bg'></div>
      <div>{children}</div>
    </ButtonStyled>
  )
})

const ButtonStyled = styled<any>(motion.button)`
  ${font15_20(true)}
  align-items: center;
  background-color: ${({ $bgType, $outline }) => $outline ? 'transparent' : handleBgType($bgType)};
  color: ${({ $bgType, $outline }) => $outline ? 'white' : handleBgTypeHover($bgType)};
  display: flex;
  height: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : `${vw(50, 'mobile')}`};
  justify-content: center;
  letter-spacing: 0;
  margin: 0 auto;
  padding: ${({ $circle }) => $circle ? 0 : `${vw(17, 'mobile')} ${vw(30, 'mobile')}`};
  position: relative;
  text-transform: ${({ $uppercase }) => $uppercase ? 'uppercase' : 'initial'};
  width: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : 'unset'};

  ${mq.greaterThan('tablet')} {
    ${font18_22(false)}
    height: ${({ $circle }: any) => $circle ? `${vw(40, 'desktop')}` : `${vw(60, 'desktop')}`};
    padding: ${({ $circle }: any) => $circle ? 0 : `${vw(19, 'desktop')} ${vw(43, 'desktop')}`};
    width: ${({ $circle }: any) => $circle ? `${vw(40, 'desktop')}` : 'unset'};
  }

  &[type='submit'] {
    ${font15_20(true)}

    ${mq.greaterThan('tablet')} {
      ${font15_20(false)}
    }
  }

  &,
  &:before {
    border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(30, 'mobile')}`};
    transition: 300ms background-color linear, 300ms border linear, 300ms color linear, 300ms filter linear, 300ms opacity linear;

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(30, 'desktop')}`};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;

    &:before {
      content: none;
      display: none;
    }
  }

  > div:last-of-type {
    align-items: center;
    color: inherit;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: 2;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);

    img {
      padding: ${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')} {
        padding: ${vw(10, 'desktop')};
      }
    }
  }

  .button__bg {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    background: ${({ $bgType, $outline }) => $outline ? 'transparent' : handleBgType($bgType)};
    border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(30, 'mobile')}`};
    border: ${({ $bgType, $outline }) => $outline ? `1px solid ${handleBgType($bgType)}` : 'none'};

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(30, 'desktop')}`};
    }
  }

  &:hover .button__bg {
    transform: ${({ $circle, $noHoverEffect }) => $noHoverEffect ? 'none' : $circle ? 'scale3d(1.2,1.2,1)' : 'scale3d(1.1,1.2,1)'};
  }

  .button__bg::before,
  .button__bg::after {
    content: '';
    position: absolute;
    z-index: -1;
    background-color: ${({ $bgType, $circle, $outline }) => $circle || $outline ? 'transparent' : handleBgTypeHover2($bgType)};
  }

  .button__bg::before {
    width: 110%;
    height: 0;
    padding-bottom: 110%;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate3d(-50%,-50%,0) scale3d(0,0,1);
  }

  &:hover .button__bg::before {
    transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
    transform: translate3d(-50%,-50%,0) scale3d(1,1,1);
  }

  .button__bg::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover .button__bg::after {
    opacity: 1;
    transition-duration: 0.01s;
    transition-delay: 0.3s;
  }
`
