'use client'
import { type FC } from 'react'
import { useParams } from 'next/navigation'
import styled from 'styled-components'
import { designGrid, font110_98, font20_24, font30_42, font72_60, mq, vw } from '@/styles'
import { Button } from '@/components/Button'
import { useTranslator } from '@/hooks'

const catalogFilenameByLang = {
  'es': 'catalogo',
  'en': 'catalog',
  'fr': 'catalogue',
  'pt': 'catalogo',
  'de': 'katalog',
}

export const HeaderHome:FC = () => {
  const { lang } = useParams()
  const { t } = useTranslator()

  return (
    <Header>
      <h1 className='srt-only'>Vi-enology | Home</h1>
      <figure className='bottom left'>
        <img src={'/images/corner-bottom-left.svg'} alt='icon image' />
      </figure>
      <figure className='bottom right'>
        <img src={'/images/corner-bottom-right.svg'} alt='icon image' />
      </figure>
      <figure className='top left'>
        <img src={'/images/corner-top-left.svg'} alt='icon image' />
      </figure>
      <figure className='top right'>
        <img src={'/images/corner-top-right.svg'} alt='icon image' />
      </figure>
      <hgroup>
        <h2 className='first'>Il vIno è il condensato di un territorio,</h2>
        <p className='second'>
          <span>{t('header_one')}</span>
          <a href={`/downloads/${lang ? lang + '' : 'es'}/${catalogFilenameByLang[lang ? lang + '' : 'es']}.pdf`} rel='noreferrer' target='_blank'><Button>{t('header_two')}</Button></a>
        </p>
        <h2 className='fourth'>di una cultura, di uno stile di vita.</h2>
      </hgroup>
    </Header>
  )
}

const Header = styled.header`
  background-color: var(--color-red);
  position: relative;

  figure {
    position: absolute;

    &.bottom {
      bottom: ${vw(70, 'mobile')};

      ${mq.greaterThan('tablet')} {
        bottom: ${vw(50, 'desktop')};
      }
    }

    &.top {
      top: ${vw(70, 'mobile')};

      ${mq.greaterThan('tablet')} {
        top: ${vw(50, 'desktop')};
      }
    }

    &.right {
      right: ${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')} {
        right: ${vw(35, 'desktop')};
      }
    }

    &.left {
      left: ${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')} {
        left: ${vw(35, 'desktop')};
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  hgroup {
    ${designGrid({})}
    grid-row-gap: ${vw(30, 'mobile')};
    height: 100%;
    padding: ${vw(170, 'mobile')} 0;

    .first {
      grid-area: 1 / 1 / span 1 / span 5;
      padding-left: ${vw(20, 'mobile')};
      padding-right: ${vw(37, 'mobile')};
    }

    .second {
      grid-area: 2 / 3 / span 1 / span 4;
      padding-right: ${vw(20, 'mobile')};
    }

    .third {
      grid-area: 3 / 1 / span 1 / span 3;
      justify-self: left;
      padding-left: ${vw(20, 'mobile')};
    }

    .fourth {
      grid-area: 4 / 3 / span 1 / span 4;
    }

    ${mq.greaterThan('tablet')} {
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 0;
      padding: ${vw(160, 'desktop')} 0 ${vw(180, 'desktop')};

      .first {
        grid-area: 1 / 2 / span 2 / span 4;
        padding-left: 0;
        padding-right: 0;
      }

      .second {
        grid-area: 1 / 7 / span 1 / span 4;
        margin-right: ${vw(51, 'desktop')};
        padding-right: 0;
      }

      .third {
        grid-area: 2 / 2 / span 1 / span 4;
        align-self: end;
        padding-left: 0;
      }

      .fourth {
        grid-area: 2 / 7 / span 1 / span 5;
      }
    }

    p , h2 {
      color: var(--color-background);
    }

    p {
      ${font20_24(true)}

      ${mq.greaterThan('tablet')} {
        ${font30_42(false)}
        letter-spacing: .62px;
        line-height: ${vw(36, 'desktop')};
      }

      > span {
        margin-bottom: ${vw(30, 'mobile')};

        ${mq.greaterThan('tablet')} {
          margin-bottom: ${vw(40, 'desktop')};
        }
      }

      a, span {
        display: block;
      }

      button {
        margin-left: 0;
      }
    }

    h2 {
      text-align: left;
      font-family: var(--font-manic);
      ${font72_60(true)}

      ${mq.greaterThan('tablet')} {
        ${font110_98(false)};
      }
    }
  }
`
